import React from "react";

const langEn = {
  headerTitle: "Read, Play & Win",
  read_play_win: "Read, Play & Win",
  login_header_2: "Read a book then take its quiz !",
  welcome_to_quizzito: "Welcome to Quizzito",
  quizzito: "Quizzito",
  use_card: "Use your card to login",
  start_btn: "START",
  continue_btn: "CONTINUE",
  confirmation: "CONFIRMATION",
  confirm_btn: "CONFIRM",
  log_in_btn: "LOG IN",
  pick_a_book_btn: "PICK A BOOK",
  pick_other_book: "PICK ANOTHER BOOK",
  read: "READ",
  another_book: "ANOTHER BOOK",
  user_name_placeholder: "User name in the card",
  passowrd_placeholder: "Password in the card",
  ranking_label: "Ranking",
  read_book_lablel: "Read books",
  points_label: "Points",
  msg_leave_game:
    "You can come back to complete the quiz later, but you will lose the current question.",
  msg_confirm_play_quiz:
    "Have you read this book and you want to take its quiz?",
  msg_leave_game_title: "ARE YOU SURE THAT YOU WANT TO LEAVE ?",
  level_label: "Level",
  leave_btn: "LEAVE",
  play_btn: "PLAY",
  yes_btn: "YES",
  no_btn: "NO",
  books_read: "Books Read",
  show_your_card: "Show your card to the camera",
  show_book_qr: "Show your book's QR code to the camera",
  after_quiz_msg1_1: "The player in the second place needs only",
  after_quiz_msg1_2: "points to take your place, read more to stay first",
  after_quiz_msg2_1: "You only need ",
  after_quiz_msg2_2: "point(s) to be ranked",
  after_quiz_msg3:
    "You are the first participant in the competition, stay focused to keep your first place.",
  score_alert_1: "Come on!",
  score_alert_2: "Read & Play",
  score_alert_3: "Focus more",
  score_alert_4: "Good job",
  score_alert_5: "Wooow!",
  user_info_phone: "Write your parent's phone number",
  user_info_full_name: "Write your full name",
  user_info_first_name: "Write your first name",
  user_info_last_name: "Write your last name",
  next: "NEXT",
};
export default langEn;
